window.RAC = window.RAC || {}; // RAC code namespace to keep from cluttering global namespace with methods and variables
window.RACGA4 = window.RAC || {}; // RACGA4 code namespace to keep from cluttering global namespace with methods and variables
window.dataLayer = window.dataLayer || []; // Google global dataLayer object (can be declared and events pushed even before GA/GTM initialize)
// Spinner and mobile-related code
RAC.loadingSpinnerShow = function(){
    if (RAC.cordovaWrapper && !RAC.loadingSpinnerManual && window.CE_WEBLEAD_NAME === "VA Lead Form" && location.href.indexOf('submit-application') !== -1){
        return; // don't show spinner in wrapper on WOF VA Lead Form unless loadingSpinnerManual is set, to prevent Android Cordova issue
    }

    if (RAC.cordovaWrapper && RAC.venmoButtonClicked && location.href.indexOf('myaccount/paymentmethods') !== -1){
        return; // don't show spinner on Payment Methods page after venmoButtonClicked flag was set (while switching to Venmo app)
    }
    jQuery('.rac-spinner-wrapper').show();
    /* !!! The following commented code is for the failsafe timeout option should we ever choose to enable it */
    //window.clearInterval(RAC.loadingSpinnerFailSafeHide);
    //RAC.loadingSpinnerFailSafeHide = window.setTimeout(RAC.loadingSpinnerHide,10000);
}
RAC.loadingSpinnerHide = function(){jQuery('.rac-spinner-wrapper').hide();}
RAC.loadingSpinnerFlag = navigator.userAgent.indexOf('Cordova') !== -1;
RAC.loadingSpinnerManual = false;
RAC.cordovaWrapper = navigator.userAgent.indexOf('Cordova') !== -1;
RAC.mobileOS = navigator.userAgent.indexOf('iPhone') !== -1 ? 'iOS' : '';
RAC.mobileOS = RAC.mobileOS || navigator.userAgent.indexOf('Android') !== -1 ? 'Android' : '';
RAC.loadingSpinnerCheck = function(event) {
    if (RAC.loadingSpinnerFlag){
    	handleSpinner();
        RAC.loadingSpinnerShow();
    }
  }
var kbaQuestionResponse;
var questionText = $(".questionText");
var qnaOpts = $('.qnaOptions');
var qnaButtons = $(".qna-btn");
var currentQuestion = 0;
var qnaBody = $(".qnaBody");
var selectedAnswersField = $("#selectedAnswers");
var selectedAnswers = [];
var formatIECta3 = $("#cta3 > a").text();
var cta3HiddenText = $('#cta3-text');
var cta3HiddenVal;
$(document).ready(function () {
    $(cta3HiddenText).val(formatIECta3)
    cta3HiddenVal = $(cta3HiddenText).val();

    $('.submenu_dropdown, #sort-filterModal').on('show.bs.modal', function () {
        if($('button#nebula_div_btn').length > 0 ){
            $('button#nebula_div_btn').hide();
        }
    });
    $('.submenu_dropdown, #sort-filterModal').on('hidden.bs.modal', function () {
        if($('button#nebula_div_btn').length > 0 ){
            $('button#nebula_div_btn').show();
        }
    });
    // Dynamic top spacing for Main Menu
    var mainMenuTopSpace;
    if($('.welcomebanner-container').length > 0) {
        mainMenuTopSpace = $('.header-wrapper').height() + $('.welcomebanner-container').height();
    } else {
        mainMenuTopSpace = $('.header-wrapper').height();
    }
    $('.modal.submenu_dropdown').css('top', mainMenuTopSpace);

	if(isMobileDevice()) {
		$('#hideMainMenu').css('top', mainMenuTopSpace);
	}
    // Fixing Account Modal Overlay Script
    $('#accountHeader, #store-details-modal').on('shown.bs.modal', function () {
        $('.header-wrapper').addClass('modal-opened');
    });
    $('#accountHeader, #store-details-modal').on('hide.bs.modal', function () {
        $('.header-wrapper').removeClass('modal-opened');
    });
})

function formatPrice(price) {
    var formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formatter.format(price);
}

window.onmessage = function (event) {
  if (event.data === "closeSignInFrame") {
    $("#headerLoginContainerSignIn").modal('hide');
  }
};

jQuery('.submenu_dropdown').on('click','a[href]',function(){
      RAC.loadingSpinnerFlag = true;
      handleSpinner();
      RAC.loadingSpinnerShow();
    })

RAC.unsetSpinner = () => {
  if (!window.pageHidden) {
    RAC.loadingSpinnerHide();
  }
};

RAC.setSpinner = () => {
  if (RAC.loadingSpinnerFlag) {
	handleSpinner();
    RAC.loadingSpinnerShow();
    window.setTimeout(RAC.unsetSpinner, 2000);
  }
};

RAC.setPageHide = () => {
  window.pageHidden = true;
  if (RAC.loadingSpinnerFlag) {
	handleSpinner();
    RAC.loadingSpinnerShow();
  }
};

RAC.unsetTelSpinner = () => {
	window.setTimeout(RAC.loadingSpinnerHide, 200);
}

// Add main spinner listener events and a pagehide & beforeunload event listener that sets a flag pageHidden = true (for iOS issues workaround)
window.addEventListener('beforeunload', RAC.loadingSpinnerCheck);
window.addEventListener("beforeunload", RAC.setPageHide);
if (RAC.mobileOS === "iOS"){
    window.addEventListener('pagehide', RAC.loadingSpinnerCheck);
    window.addEventListener("pagehide", RAC.setPageHide);
}
// Add a jQuery(‘body’).on(‘click’,’a[href]’) event listener that shows the spinner but also sets a short timeout to call spinnerHide() if pageHidden isn’t true
// This works for all hyperlinks but not JS-triggered navigation events or page refreshes.
jQuery("body").on("click", "a[href]:not([href^='javascript']):not([href^='#']):not([href^='tel']):not([href^='mailto']):not([href=''])", RAC.setSpinner);
jQuery("body").on("click", "a[href^='tel'],a[href^='mailto']", RAC.unsetTelSpinner);

// Separately, Change all code that directly sets window.location.href to use a global static method instead; let that static method start the animation before calling window.location.href.
RAC.globalRedirect = function(newUrl) {
  var loadNewUrl = () => {
    window.location.href = newUrl;
  };
  if (window.RAC && window.RAC.loadingSpinnerFlag) {
	handleSpinner();
    RAC.loadingSpinnerShow();
    setTimeout(loadNewUrl, 50); //navigate after 50ms to give Safari time to show the loading spinner
  } else {
    loadNewUrl();
  }
};

// Analytics & data layer code - DTM calls to be replaced by GA data layer pushes
RAC.dataLayerStoreAssigned = function(zip, id){
    window.dataLayer.push({
        'event': 'dataLayer-store-assigned',
        'storeZip': zip,
        'storeId': id
    });
}

RAC.dataLayerStoreChanged = function(zip, id){
    window.dataLayer.push({
        'event': 'dataLayer-store-changed',
        'storeZip': zip,
        'storeId': id
    });
}
$.extend({
	storeCookieData: function() {
		var storeCookieData = storeData;
		var storeDataCookie = getCookieByName("storeCookie");
		if (storeDataCookie != "") {
			storeCookieData = $.parseJSON(decodeURIComponent(storeDataCookie).replace(/\+/g, " "));
		}
		return storeCookieData;
	}
});

$.extend({
	storeCookie: function(name) {
		storeData = $.storeCookieData();
		if (storeData.length != 0) {
			return storeData[name];
		} else {
			return "";
		}
	}
});

var _satellite = _satellite || {};
_satellite.track = _satellite.track || (function () { console.log("Adobe DTM not loaded.", arguments); });
var RACeVar93, RACeVar94, RACeVar96;
RAC.Header = {
	init: function() {
		this.location();
		this.map();
	},

    location: function () {
        /*** reading cookie at load of the page ***/
        if ($.storeCookieData().length == 0) {
            var pricingvalue = $('#continueWithoutPricingFlag').val();
            if (pricingvalue != 'false') {
                /** Auto Location * */
                getLocation();
            }
        }
    },

    map: function () {

        /** Search for Stores on Enter * */
        $('.enter-zip-code').on('keypress', function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
                $("#enterZipCodeGoButton_Nav").trigger('click');
            }
        });

        /** Continue Without Pricing * */
        $('body').on('click', '#continueWithoutPricing', function (e) {
            e.preventDefault();
            $.ajax({
                url: '/find-a-store/setPriceOption',
                type: "get",
                success: function (data) {
                    $(".locationSearchCloseButton").trigger("click");
                },
                error: function (error) {
                }
            });
        });

        /** * JQUERY for the store locator pop up** */

        $(".change-store-link").
            on('click', function () {
                $(".header-store-locator").trigger('click');
            });
    }
};

function ctaDataPush(name, position) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': 'cta-click',
			'ctaName': name,
			'ctaLocation': position
		});
}

function fPBannerDataPush(name, position) {
	var currentUrl = window.location.href;
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'event': 'FP-click',
		'fpBannerName': name,
		'fpPosition': position,
		'Page URL': currentUrl
	});
}

function getCookieByName(cname) {
    var response = "";
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            response = c.substring(name.length, c.length);
            return response;
        }
    }

    return response;
}

function getPosition(position) {
    RACeVar94 = "allowed";
    RACeVar93 = window.location;
    var crd = position.coords;
    var curLat = position.coords.latitude,
        curLong = position.coords.longitude;
    var ifStoresValue;
    var Geoaccuracy = JSON.stringify(crd.accuracy);
    RACeVar96 = Geoaccuracy + ' ' + 'meters.';
    $('.error-txt').remove();
    $('.error-txt-pop-up').remove();
    if (RAC.coreDataLayer.pageType == 'home') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'geolocation',
			'geoUserAction': 'Allow',
			'geoLatitude': curLat,
			'geoLongitude': curLong,
			'geoAcuracy': Geoaccuracy
        });
    }
    setCookie('eVar93', RACeVar93);
    setCookie('eVar94', RACeVar94);
    setCookie('eVar96', RACeVar96);
    if ($.storeCookieData().length == 0) {
    var postlaCodeURL = "/find-a-store/postalcode?firstTime=true&latitude=" + curLat + "&longitude=" + curLong;

    $.ajax({
        url: postlaCodeURL,
        type: "get",
        contentType: 'application/json',
        success: function (data) {
            var object = JSON.parse(data);
            if (isElemExist('.my-store-card')) {
                assignStoreToExchangeCard(object);
            }
            storeData = $.storeCookieData();
            if (storeData.length != 0) {
                setCookie("ifStores", "Yes", 7, "", true);
            } else {
                setCookie("ifStores", "No", 1, "", true);
            }
            displaySubmitContactInfoLink();

            if ($("#modelSinglePage").val() == "true" && storeData.length != 0) {
				 var storeTown = storeData.town;
				 var zipCode = storeData.zipCode;
				 var isoShortCode = storeData.isoShortCode;
				 var streetNumberStore = storeData.streetNumber;
				 var streetNameStore = storeData.streetName;
				 var phoneStore = storeData.phone;
				 var storeDistance = storeData.distance;
	             $(".assignstorebtn").hide();
	             $(".changestorebtn").show();
	             $(".addressNDistance").show();
	             $(".nostore-info").hide();
	             $(".nostore-infoh").hide();
	             setCookie("DeliveryStoreAssigned", "Yes", 7, "", true);

	             $("#leadFormStoreInformationHeader")
	             .empty()
	             .append(
	                 "<div>"
	                                + formatStoreAddress(streetNameStore, streetNumberStore,
	                                         storeTown,
	                                        isoShortCode, zipCode) + "</div><a href='tel:"
	                                + phoneStore + "' title='tel:" + phoneStore
	                                + "'>" + phoneStore + "</a>");


	            $("#storeDistance")
	             .empty()
	             .append(
	                 formatStoreDistance(storeDistance) +
	                     " <div class='miles'>" + ACC.miles + "</div>"
	             );
	            }

            ifStoresValue = getCookieByName("ifStores");

            if (ifStoresValue == "Yes") {
                setCookie("geoFirstTime", "Yes", 7, "", true);
                setCookie("geolocationselected", "Yes", 7, "", true); //allow
                setCookie("geolocationblocked", "No", 7, "", true); // block
            }
            else {
                setCookie("geoFirstTime", "Yes", 1, "", true);
                setCookie("geolocationselected", "Yes", 1, "", true); //allow
                setCookie("geolocationblocked", "No", 1, "", true); // block
            }

            if (Object.keys(object) == "errorMessage") {
                if ($(window).width() < 768) {
                    // $("#mobileNavMapButton .storeLocatorPopup_link").trigger('click');
                    $("#mobileNavMapButton .header-store-locator").trigger('click');
                }
                else {
                    // $(".nav-links-left .storeLocatorPopup_link").trigger('click');
                    $(".nav-links-left .header-store-locator").trigger('click');

                }

                $('.storeContainerInputForm').css('display','none');

                $('.skipSelectAStore').css("display","none");

                $(".showAvailableStore").css("display","block");
                var unavailabelStore;
                var dummyErrorResponsejQobj = "";
                if (object) {
                    dummyErrorResponsejQobj = $($.parseHTML(object.errorMessage));
                }
                // Changing <img> src attribute to correct src url
                if (dummyErrorResponsejQobj.find('img').length > 0) {
                    var imgSrcToChange = window.location.protocol + '//' + window.location.host + dummyErrorResponsejQobj.find('img').attr('src').split('..')[1];
                    dummyErrorResponsejQobj.find('img').attr('src', imgSrcToChange);
                    unavailabelStore = dummyErrorResponsejQobj.wrap('<p/>').parent().html();
                } else {
                    unavailabelStore = object.errorMessage;
                }
                $('.storeContainerInputForm').css('display','block');
                $("#stores-available").css("display","none");

                $(".showAvailableStore").empty().append('<p class="error-txt-no-store">' + unavailabelStore + '</p>');
            } else {
                if (localStorage != null) {
                    localStorage.setItem("geoLocatedStore", JSON.stringify(object));
                }
                setCookie("assignByGeo", "true", 1, "", true);
                if (localStorage.getItem('makeMyStoreClicked') == 'Yes') {
                    localStorage.setItem('makeMyStoreClicked', 'Yes');
                    displayMyStore();
                } else {
                    displayGeoZip();

                }
            }
        },
        error: function (error) {
			console.log(error);
        }
    });
  }
}

function handleLocationError(error) {
    RACeVar94 = "blocked";
    RACeVar93 = window.location;
    if (RAC.coreDataLayer.pageType == 'home') {
        window.dataLayer = window.dataLayer || [];
		switch (error.code) {
		case 1:
			// userdenied or block location prompt.
			window.dataLayer.push({
            'event': 'geolocation',
            'geoUserAction': 'Deny',
            'geoEror': 'User denied the request for Geolocation'
			});
		break;
		case 2:
			// users device can't get data.
			window.dataLayer.push({
				'event': 'geolocation',
				'geoUserAction': 'Allow',
				'geoEror': 'Location information is unavailable'
			});
		break;
		case 3:
			// deal with timeout.:
			window.dataLayer.push({
				'event': 'geolocation',
				'geoUserAction': 'Allow',
				'geoEror': 'The request to get user location timed out'
			});
		break;
		default:
			window.dataLayer.push({
				'event': 'geolocation',
				'geoUserAction': 'Allow',
				'geoEror': 'An unknown error occurred'
			});
		}
    }
    setCookie('eVar93', RACeVar93);
    setCookie('eVar94', RACeVar94);
    setCookie('eVar96', RACeVar96);

    setCookie("geolocationselected", "Yes", 1, "", true);
    setCookie("geoFirstTime", "Yes", 1, "", true);
    setCookie("geolocationblocked", "Yes", 1, "", true);
    setCookie("ifStores", "No");
    if (typeof (errorCallback) !== 'undefined' && errorCallback != null) {
        errorCallback();
    }
}

function getLocation() {
    var geoFirstTimeA = getCookieByName("geoFirstTime");
    var geoSelectedA = getCookieByName("geolocationselected");
    if (geoFirstTimeA == "" && geoSelectedA == "") {
        if (navigator.geolocation) {
            var locationOptions = {
                enableHighAccuracy: true,
                maximumAge: 30000,
                timeout: 10000
            };
            navigator.geolocation.getCurrentPosition(function (position) { getPosition(position) }, handleLocationError, locationOptions);
        }
    }
}


function setCookieByName(cname, cvalue) {
    document.cookie = cname + "=" + cvalue + "; expires=-1";
}

function setCookie(name, value, expirationDays, path, secure) {
    var cookieValue = name + "=" + value;
    if (!path) {
        path = '/';
    }
    if (typeof expirationDays !== "undefined") {
        var date = new Date();
        date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
        cookieValue += "; expires=" + date.toUTCString();
    }
    if (path.length > 0) {
        cookieValue += "; path=" + path;
    }
    if (typeof secure !== "undefined" && secure) {
        cookieValue += "; Secure";
    }
    document.cookie = cookieValue + ";path=/";
}
function deleteCookie(cookieName) {
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}
//This function takes expiry time in ms which is directly added to current time as expiry
function setCookieWithDynamicTime(name, value, time, path, secure) {
	
    var cookieValue = name + "=" + value;
    if (!path) {
        path = '/';
    }
    if (typeof time !== "undefined") {
        var date = new Date();
        date.setTime(date.getTime() + time);
        cookieValue += "; expires=" + date.toUTCString();
    }
    if (path.length > 0) {
        cookieValue += "; path=" + path;
    }
    if (typeof secure !== "undefined" && secure) {
        cookieValue += "; Secure";
    }
    document.cookie = cookieValue + ";path=/";
}
function searchBoxChange() {
    $("#input_searchComponent").click('input', function () {

        if ($(window).width() < 768) {
            $(".header-signin-wr").hide();
            $(".headerIconContainer-wr").hide();
            $("#right").hide();
            $("#left").hide();
            $(".header-global-wr").css('padding', '0px 10px');
            $("#middle input").css("width", "100%");
            $("#middle").removeClass('col-xs-5').addClass('col-xs-12');
            $("#middle").css("margin-right", "0");
            $("#middle").css("margin-left", "0");
            $(".cancelIcon").css("display", "inline-block");
            $(".fa-times-circle").css("display", "inline-block");
        } else {
            $(".header-signin-wr").show();
            $(".headerIconContainer-wr").show();
            $("#left").show();
            $("#right").show();
            $("#middle").removeClass('col-xs-8').addClass('col-xs-5');
            $("#middle").css("margin-right", "110px");
            $("#middle").css("margin-left", "106px");
            $(".cancelIcon").css("display", "none");
            $(".fa-times-circle").css("display", "none");
            $(".yCmsContentSlot").removeAttr("style")
        }
    });
}


var freqSearchComponent = $(".frequently-search-component");
$(document).ready(function () {
    $(".signUpDealsShortForm  #emailTerm").attr("placeholder", $("#emailAddressLabel").val());
    searchBoxChange();
    clearAndDonebtns();
    $(".cancelIcon").click(function () {
        $("#input_SearchBox").val("");
        $(".header-signin-wr").show();
        $(".headerIconContainer-wr").show();
        $("#right").show();
        $("#left").show();
        $("#middle").removeClass('col-xs-8').addClass('col-xs-5');
        $("#middle").css("margin-right", "110px");
        $("#middle").css("margin-left", "106px");
        $(".header-global-wr").css('padding', '0px 10px 0px 20px');


        $(".cancelIcon").css("display", "none");
        $(".fa-times-circle").hide();
        freqSearchComponent.hide();
        $("#rac-header-nav").removeClass("searchlayoutMob");
        if ($(window).width() == 320) {
            $(".siteSearch").css("width", "75%");
        }
        else {
            $(".siteSearch").css("width", "100%");
        }
    });
    var quickLinksCount = $(".quick-links").children().length;
    if (quickLinksCount % 3 == 0) {
      $(".quick-links").children().addClass("quickLinksWidth");
    } else {
      $(".quick-links").children().removeClass("quickLinksWidth");
    }
    var width = $(".quick-links").children().width()/ $('.quick-links').width() * 100;
    if (width <= 25 ) {
        $(".quick-links").children().addClass("wrapQuickLinksText");
    } else {
      $(".quick-links").children().removeClass("wrapQuickLinksText");
    }
	
	$(document).click(function (e) {
	    if ($(e.target).is(".siteSearchInput") || $(e.target).is(".frequently-search-component")) {
	        var searchInputLength = $(".siteSearchInput").val().length;
	        showHideFrequentlySearch(searchInputLength);
	    }
	    else {
	        $('.frequently-search-component').hide();
	        $("#rac-header-nav").removeClass("searchlayoutMob");
	    }
	});

	$("#input_searchComponent").on('click', function () {
		freqSearchComponent.show();
		freqSearchComponent.find(".row").find('h2').parent().css("cursor", "auto");
		$("#rac-header-nav").addClass("searchlayoutMob");
	});

	$search = $(".siteSearchInput");
	$search.on("keyup", function () {
		var searchInputLength = $(this).val().length;
		showHideFrequentlySearch(searchInputLength);
	});
	$search.on("focus", function() {
    	$('.header-wrapper').addClass('position-static modal-opened');
    	if($('.header-wrapper').hasClass('menu-opened')) {
            $('#hideMainMenu').trigger('click');
        }
		$("#input_searchComponent").trigger('click');
	});
	$search.on("blur", function() {
	    $('.header-wrapper').removeClass('position-static modal-opened');
	});
	
	$(".frequently-search-component div.row div").click(function () {
		var searchLink = $(this).find("a");
		if (searchLink.length > 0) {
			window.open($(searchLink).attr("href"), '_self');
		}
		return false;
	});

	$("#sessionTimeoutSignIn").on("click", function () {
		$('#sessionTimeoutContainer').modal('hide');
		headerLoginContainerSignIn('false');
	});

	$(window).on("load", function () {
		$('#signInIframe').trigger("updateIframe");
		var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);
		if (isIE11) {
			$('#headerLoginContainer').css('height', '500px');
		}
		setTimeout(function () {
			$('#signOutToolTip').css('display', 'none');
		}, 3000);
	});

	$(window).resize(function () {
		searchBoxChange();
		clearAndDonebtns();
	});
	let attributionToken = $('#attributionToken').attr('data-value');
    let termPosition = $('#termPosition').attr('data-value');
	if (!isNullUndefined(termPosition) && !isNullUndefined(attributionToken)) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'search-vertex',
            'completionAttributionToken' : attributionToken,
            'selectedPosition' : termPosition
        });
    }

});

function clearAndDonebtns() {
    if (document.getElementById("prodId") != null) {
        var searcString = document.getElementById("prodId").value;
    }
    if ($(window).width() < 768) {
        if (searcString != null && window.location.href.split('?')[0] + '?text=' + searcString != window.location.href) {
            $("#clearText").removeAttr("style");
            $("#doneText").removeAttr("style");
        } else {
            $('#clearText').css("display", "none");
            $('#doneText').css("display", "none");
        }
    } else {
        $("#clearText").removeAttr("style");
        $("#doneText").removeAttr("style");
    }
}


function headerLoginContainerSignIn(isExpressCheckout) {
    document.cookie = "expressCheckoutRedirectURLCookie=;Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
    localStorage.setItem('viewEnable', false);
    sessionStorage.setItem( 'preApproved', false);
    sessionStorage.setItem( 'viewFlag', false);

    if (typeof (jQuery) !== "function" || typeof (jQuery("#headerLoginContainerSignIn").modal) !== "function") {
        return false;
    }
    $(".signInLoading").hide();
    $("#headerLoginContainerSignIn").modal({ backdrop: 'static', keyboard: false });
    $('#headerLoginContainerSignIn').css('display', 'block');
    $('#headerLoginContainerSignIn').addClass('in');
    $('#signOutToolTip').css('display', 'none');
    if($("#forgorPwdLink").length){
    	$('#forgorPwdLink').css("color","#0f99d6");
    }

	// Close my-offers component on opening of signin modal
	if (isElemExist('.my-offers') && $('.my-offers').hasClass('active')) {
		$('#offerAccordian').trigger('click');
	}

	if (isExpressCheckout == 'true') {
		var expressCheckoutRedirectURL = "/submit-application" + location.search;
		setCookie("expressCheckoutRedirectURLCookie", expressCheckoutRedirectURL);
		var iframeSrc = ACC.ssoLoginContextPath + "/signin-express-checkout.html?fromURI=" + ACC.oktaPreviewURL
			+ ACC.oktaPreviewEnv + "/" + ACC.oktaPreviewAppId + "/sso/saml?RelayState=" + window.location.origin
			+ ACC.config.encodedContextPath + expressCheckoutRedirectURL;
    } else {
        if ($('#tab_selected').text() == "cart") {
            var redirectUrl = "/cart?tabType=cart";
            setCookie("myCartRedirectURLCookie", redirectUrl);
        } else if ($('#tab_selected').text() == "saved") {
            var redirectUrl = "/cart?tabType=saved";
            setCookie("myCartRedirectURLCookie", redirectUrl);
        }
        var iframeSrc = "/racsso/saml/";
	}
	if (isExpressCheckout == 'false') {
	    var setIframeLoadFlag = true;
	    $("#signInIframe").on("load", function () {
	        setIframeLoadFlag ? $(".signInLoading").show() : $(".signInLoading").hide();
	        setIframeLoadFlag = false;
	    });
	    var currentUrl = window.location.pathname;
	    $.ajax({
	        url: ACC.saveURLForSignin,
	        type: "get",
	        data: JSON.stringify(currentUrl),
	        contentType: 'application/json',
	        success: function (response) {
                console.log(response);
                if (response==="false") {
                    console.log("reload");
                    location.reload();
                }
	        },
	        error: function () {}

	    });
	}
		var iframeEle = '<iframe src="' + iframeSrc + '" width="100%" scrolling="auto" id="signInIframe" height="480px"></iframe>';
    	        $("#oktaSignin").empty().append(iframeEle);

    if ($("#signInWrapper").length) {
        $('html, body').animate({
            scrollTop: $("#page").offset().top - 50
        }, 1000);
    }
    $("div.modal-backdrop").remove();
}

$('.lang-toggle').click(function(){
	handleSpinner();
	$(".rac-spinner-wrapper").show();
	$('#selectedLang').val($(this).data('lang'));
	if($(this).data('currentLang') != 'true'){
		$('#lang-form').submit();
	}
	return false;
})

function handleSpinner(){
	var spinnerImg;
	spinnerImg = document.getElementById("racSpinner");
	if ((spinnerImg.src.indexOf('RAC_Spinner') == -1)){
		spinnerImg.src = "/_ui/responsive/common/images/RAC_Spinner.png";
	}
}

function showHideFrequentlySearch(searchInputLength) {
    if (searchInputLength == 0) {
        freqSearchComponent.show();
        freqSearchComponent.find(".row").find('h2').parent().css("cursor", "auto");
        $("#rac-header-nav").addClass("searchlayoutMob");
        if ($(window).width() < 768) {
            var headerWidth = $(".header-wrapper").width();
            freqSearchComponent.width(headerWidth);
        }
    } else {
        freqSearchComponent.hide();
        $("#rac-header-nav").removeClass("searchlayoutMob");
    }
}

$(window).load(function() {
    var isGaIdPresent=  $('.js-gaUniqueId').text();
    try {
        if (isGaIdPresent != null && isGaIdPresent === 'false') {
            var gaId = ga.getAll()[0].get('clientId');
            if (gaId !== undefined && gaId != null) {
                $.ajax({
                    url: '/setGaUniqueID?googleAnalyticsId=' + gaId,
                    type: "GET",
                    async: true,
                    success: function (data) {
                    },
                    error: function (error) {
                    }
                });
            }
        }
    }catch (e) {
    }
});

// Main Search Form Submit Script
$('#mainSearchForm').on('submit', function(e) {
	e.preventDefault();
	let redirectOpenNewWindow = $('#mainSearchForm').attr('data-redirectOpenNewWindow');
	let keywordRedirectUrl = $('#mainSearchForm').attr('data-keywordRedirectUrl');
	if(redirectOpenNewWindow === 'true' && keywordRedirectUrl != null) {
		window.open(keywordRedirectUrl, '_blank');
	} else {
		$('#mainSearchForm').unbind('submit').submit();
	}
});
// On click of search suggestions open vendor link in new tab script
$(document).on('click', '.ui-autocomplete .suggestions', function(e) {
	e.preventDefault();
	let redirectFlag = $(this).find('a').attr('data-redirectflag');
	let redirectURL = $(this).find('a').attr('data-redirecturl');
	let url = $(this).find('a').attr('href');
	if(redirectFlag === 'true' && redirectURL != null) {
		window.open(redirectURL, '_blank');
	} else {
		window.location.href = url;
	}
})