ACC.autocomplete = {

	bindAll: function ()
	{
		this.bindSearchAutocomplete();
	},

	bindSearchAutocomplete: function ()
	{
		// extend the default autocomplete widget, to solve issue on multiple instances of the searchbox component
		$.widget( "custom.yautocomplete", $.ui.autocomplete, {
			_create:function(){
				
				// get instance specific options form the html data attr
				var option = this.element.data("options");
				
				// set the options to the widget
				this._setOptions({
					minLength: option.minCharactersBeforeRequest,
					displayProductImages: option.displayProductImages,
					delay: option.waitTimeBeforeRequest,
					autocompleteUrl: option.autocompleteUrl,
					source: this.source
				});
				// call the _super()
				$.ui.autocomplete.prototype._create.call(this);
				
			},
			options:{
				cache:{}, // init cache per instance
				focus: function (){
					return false;}, // prevent textfield value replacement on item focus
				select: function (event, ui){
				}
			},
			_renderItem : function (ul, item){

				if (item.type == "autoSuggestion"){
					var renderHtml = "<a href='" + item.url + "' class='clearfix' data-redirectflag='" + item.redirectFlag + "' data-redirecturl='" + item.redirectURL + "'>" + item.value + "</a>";
					return $("<li class='suggestions'>")
							.data("item.autocomplete", item)
							.append(renderHtml)
							.appendTo(ul);
				}
				else if (item.type == "productResult"){
					var searchText = $(this.element[0]).val();
					if(item.index == 0 ) {
						var viewAllUrl = ACC.config.encodedContextPath + "/search?text=" +searchText;
						var productLabel = $('#productsLabel').val();
						var productTitleHtml = 	"<span class='product-title'>"+productLabel+"</span>";
						
						var viewAllLabel = $('#viewAllLabel').val();
						productTitleHtml += "<span class='view-all-wrapper'><a class='view-all-link' href='" + viewAllUrl + "'>"+viewAllLabel +"</a></span>";
						$("<li class='product product-header'>").append(productTitleHtml).appendTo(ul);
					} 

						var renderHtml = "<a href='" + item.url + "' class='product search-product-link clearfix'>";

						var imageURL='';
						if(ACC.imageKitEnabled != undefined && ACC.imageKitEnabled){
							 imageURL=item.image+ACC.imageKitThumbnailTransformations;
						}else{
							imageURL=item.image;
						}
						if (item.image != null){
							renderHtml += "<span class='thumb'><img src='" + imageURL + "' /></span>";
						}
						renderHtml += 	"<span class='desc clearfix'>";
						var bolderSearchText = "<span class='search-text'>"+searchText+"</span>";
						
						var pattern = new RegExp(searchText, 'gi');
						var title = item.value.replace(pattern,bolderSearchText );
						
						renderHtml += 	"<span class='title'>" + title +"</span>";
						//renderHtml += 	"<span class='price'>" + item.price + "</span>";
						renderHtml += 	"</span>";
						renderHtml += 	"<span><img class='caret-right marginL3' width='6' height='10' alt='bluerightArrow' src='/_ui/responsive/common/images/RAC-iconImages/Button-arrow-blue.svg'></span>";
						renderHtml += 	"</a>";
	
						return $("<li class='product'>").data("item.autocomplete", item).append(renderHtml).appendTo(ul);
				}
			},
			source: function (request, response)
			{
				var self=this;
				var term = request.term.toLowerCase();
				if (term in self.options.cache)
				{
					return response(self.options.cache[term]);
				}

				$.getJSON(self.options.autocompleteUrl, {term: request.term}, function (data)
				{
					$('#mainSearchForm').attr('data-redirectOpenNewWindow', data.redirectOpenNewWindow);
					$('#mainSearchForm').attr('data-keywordRedirectUrl', data.keywordRedirectUrl);
					var autoSearchData = [];
					if(data.suggestions != null){
						$.each(data.suggestions, function (i, obj)
						{
							autoSearchData.push({
								value: obj.term,
								url: ACC.config.encodedContextPath + "/search?text=" + obj.term,
								redirectFlag: obj.redirectOpenNewWindow,
								redirectURL: obj.keywordRedirectUrl,
								type: "autoSuggestion"
							});
						});
					}
					if(data.products != null){	
						$.each(data.products, function (i, obj)
						{ 			  
							autoSearchData.push({
								index:i,
								value: obj.name,
								code: obj.code,
								desc: obj.description,
								//manufacturer: obj.manufacturer,
								url: ACC.config.encodedContextPath + obj.url,
								//price: (obj.price !=null && obj.price !='' )? obj.price.formattedValue : "",
								type: "productResult",
								image: (obj.images!=null && self.options.displayProductImages) ? obj.images[0].url : null // prevent errors if obj.images = null
							});
						});
					}
					self.options.cache[term] = autoSearchData;
					return response(autoSearchData);
				});
			},
			_resizeMenu: function() {
				var searchWidth = $(this.element[0]).parent().width();
				var autoCompleteEle = $(this.bindings[1]);
				$(autoCompleteEle).width(searchWidth);
				$(autoCompleteEle).find('.product-header').removeClass("ui-menu-item");
			}
		});
		$search = $(".siteSearchInput");
	if($search.length>0){
		$search.yautocomplete();
		}
	}
};

$(document).ready(function ()
{
	ACC.autocomplete.bindAll();
	
});
